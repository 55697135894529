@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* bannerTwo.css */

h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600; /* Use font-weight: 600 as per Figma */
  font-size: 24px; /* Change to 32px for larger screens */
  line-height: 37px; /* Line height */
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 400; /* Use font-weight: 400 as per Figma */
  font-size: 14px; /* Change to 14px */
}
.flex-container {
  height: 100%; /* or a specific height, e.g., 100vh or 400px */
}
.error-message {
  background-color: #ffe6e6; /* Light red background */
  color: #d8000c; /* Dark red text color */
  font-size: 14px;
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #d8000c; /* Border to make it stand out */
  border-radius: 4px; /* Rounded corners */
}

/* Custom styles for react-phone-input-2 */
/* .phone-input {
  width: 100% !important;
}

.phone-input .form-control {
  width: 100% !important;
  height: 38px !important;
  padding-left: 48px !important;
}

.phone-input .flag-dropdown {
  border-radius: 6px 0 0 6px !important;
}

.search-class {
  margin: 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #cacaca;
} */
/* In your global CSS file */
.custom-message {
  @apply flex items-center;
}

.ant-message-notice-content {
  @apply shadow-lg border border-gray-100;
}
